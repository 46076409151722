$light: #fff;
$base: #007CDE;
$primarily: #9b9b9b;
$light-gray: lighten($primarily, 35);
$gray: rgba($primarily, 0.3);
$accent: #016ec2;

.customer-upload-xls {
    label {
            border-radius: 50px !important;
            border: 1px solid #007CDE !important;
        span {
            background-color: transparent !important;
            color: #007CDE !important;
            border-radius: 50px !important;
            border: 0px solid #007CDE !important;
            span {
                border-radius: 50px !important;
                border: 0px solid #007CDE !important;
            }
        }
    }
}

.customer-upload-xls-disabled {
    background-color: #FFFFFF !important;
    label {
        border-radius: 50px !important;
        border: 1px solid #007edec0 !important;
        background-color: #FFFFFF !important;
       span {
            background-color: #FFFFFF !important;
            color: #007edec0 !important;
            border-radius: 50px !important;
            border: 0px solid #007edec0 !important;
            span {
                background-color: #FFFFFF !important;
                border-radius: 50px !important;
                border: 0px solid #007edec0 !important;
            }
        } 
    }
    span {
        background-color: #FFFFFF !important;
        color: #007edec0 !important;
        border-radius: 50px !important;
        border: 0px solid #007edec0 !important;
        span {
            border-radius: 50px !important;
            border: 0px solid #007edec0 !important;
        }
    } 
}

.right-chevron-warpper {
    filter: drop-shadow(0px 1px 1px rgba(60, 60, 60, 0.87));
    .right-chevron-shape {
        clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
        background-color: #ffffff;
        width:100%;
        height:100px;
        }
}

.chevron-num-style {
    width: 25px;
    height: 25px;
    border: 1px solid #007CDE;
    border-radius: 50%;
    color: #007CDE;
}

.chevron-text {
    color: #007CDE;
    padding-left: 24%;
    padding-right: 24%;
    text-align: center;
}

.chevron-active  {
    background-color: #007CDE !important;
    .chevron-num-style {
        width: 25px;
        height: 25px;
        border: 1px solid #ffffff;
        border-radius: 50%;
        color: #ffffff;
    }
    .chevron-text {
        color: #ffffff;
        padding-left: 24%;
        padding-right: 24%;
        text-align: center;
    }
}

// .accordion-button::after {
//     background-image: none !important;
// }

.red-box {
    background-color: red;
    width: 15px;
    height:15px;
    padding-right: 15px;
}

.accordion-button:not(.collapsed) {
    color: #ffffff !important; 
    background-color: #ffffff !important;
}

.accordion-button:focus {
    z-index: 3;
    border-color: transparent !important; 
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0);
}

.error-highlight {
 button {
    background-color: #ff2b2ba2 !important;
    -webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
 }
}

@-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

.position-relative {
    position: relative;
}

.absolute-enteries {
    position: absolute;
    right: 0px;
}

.add_remove_btn_position {
    right: -10px;
    position:absolute;
    align-self: center;
}
.position-btn {
    position:absolute;
    top:10px;
    right:10px;
    z-index: 300;
}

.delete-btn-style {
    border-radius: 50px;
    background-color: #ff0000 !important;
    color: #ffffff !important;
    padding: 9px 25px;
    border: 0px solid #ffffff;
    z-index: 200;
    &:hover {
        background-color: #d60000 !important;
    }
    svg {
        path {
            fill :#ffffff !important;
            &:hover {
                color: #ffffff !important;
            }
            }
        }
}

.accordion-button {
    margin-right: 0 !important;
}

.primary-color {
    color:#007CDE !important;
    span {
    color:#007CDE !important;
    }
    a {
        span {
            color:#007CDE !important;
        }
    }
}

.secondary-color {
    color:#02AF95 !important;
    span {
    color:#02AF95 !important;
    }
    a {
        span {
            color:#02AF95 !important;
        }
    }
}


.svg-primary {
    color:#007CDE !important;
    cursor: pointer;
    svg {
        fill :#007CDE !important;
        path {
            fill :#007CDE !important;
        }
    }
}

.danger-color {
    color: #ff0000 !important;
    span {
    color: #ff0000 !important;
    }
    a {
        span {
            color: #ff0000 !important;
        }
    }
}

.mobile-view {
    display: none !important;
    position: relative;
    div{
        button::after {
            content: none !important;
           
            // .dropdown-toggle::after {
            //     content: none !important;
            // }
        }
        button {
            position:absolute;
            left: 2px !important;
            top: -20px !important;
            background-color: transparent !important;
            color: #007CDE !important;
            border-radius: 50% !important;
            border: 1px solid #007CDE;
            width:40px;
            height:40px;
            @media (max-width: 400px) {
                left: 25px !important;
            }
            svg {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
            }
           
        }
    }
}


@media (max-width: 778px) {
    .not-mobile-view {
        display: none !important;
    }
    .mobile-view {
        display: flex !important;
    }
}

.dropdown-menu-style {
    button {
        background-color: #ffffff !important;
        span {
            div{
                span {
                    svg {
                        path {
                            fill :#007CDE !important;
                        }
                    }
                }
            }
        }
    }
}

.discount-img {
    width: 5em;
}

.cus-discount {
    display: flex;
    justify-content: center;
}

.flip-form {
    display: inline-block; 
    direction: rtl;
}

.position-absolute {
    position: absolute !important;
    width:2em;
    left: 25%;
    top:10px;
    z-index: 40;
}

.position-absolute-text {
    position: absolute !important;
    width:2em;
    margin-left: 5px !important;
    margin-top: 5px !important;
    left: 25%;
    top:12px;
    z-index: 40;
}

.position-absolute-bar {
    position: absolute !important;
    width:300px;
    right:0;
}

.position-relative-discount {
    position: relative;
}

.position-relative-table {
    position: relative !important;
}

.postion-absolute-table {
    position: absolute !important;
    color: #0FBEA3;
    right: 3px;
    bottom: 3px;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.btn-check:focus {
    outline: none;
    box-shadow: none;
}

.remove-lable-bottom {
    label {
        margin-bottom: 0px !important;
    }
}

.remove-switch-bottom {
    margin-bottom: -7px !important;
}

.label:focus {
    outline: none;
    box-shadow: none;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-group > .btn-check:focus{
    outline: none;
    box-shadow: none;
}

.primary-background {
    background-color: #007CDE !important;
}

.danger-background {
    background-color: #F14254 !important;
}

.min-width-items {
    min-width: 200px !important;
}

#toggle-border-radius {
    border-radius: 0px !important;
    @include respond-below('xs') {
        width: 100% !important;
        box-sizing: border-box !important;
        object-fit: contain !important;
    }
}

.btn-group > .btn {
    border-radius: 0 !important;
}

.mar-1 {
    margin-left: 1px !important;
    @include respond-below('xs') {
        margin-left: 0px !important;
    }
}

.rbc-timeslot-group {
    display: block !important;
}

.rbc-row-bg {
    display: block !important;
}

.overlay-warpper {
    position: relative;
}

.overlay-div {
    position:absolute;
    width: 100%;
    height: 100%;
    background-color: #007edebd;
    z-index: 300;
}

.download-template {
    position: absolute;
    font-size: 10px;
    bottom: -1.4em;
}

.alert-style {
    top: 5em;
}

.payout-out {
    border-radius: 5px !important;
    background-color: #d1d1d1 !important;
    border: 1px solid #c2c2c2 !important;
}

.min-wdithgp {
    min-width: 50px !important;
}

.image-label {
    background-color: #7D7D7D;
    position: absolute;
    bottom: 0;
    width: 100%;
    height:50px;
    padding: 4px;
}

.image-label-text {
    display: flex;
    justify-content: start;
    align-items: start;
    text-align: start !important;
    font-size: 10;
}

.drag-overflow-container {
    overflow-y: scroll;
    z-index: -2
}

.close-btn-color {
    button {
        span {
            color:rgba(60, 60, 60, 0.87) !important;
        }
    }
}

.job-group-step-3 {
    div {
        div {
            overflow-x: visible !important;
        }
    }
}

.min-width-font-size {
    @include respond-below('xs') {
        font-size: 16px !important;
    }
}

.table-page-info {
    @include respond-below('xs') {
       width: 100% !important;
    }
}

.table-pagination-row-per-page {
     border-bottom: 'none';
     margin-left:20px ;
     position:'absolute'; 
     top:-7px;
     left: 90px;
     @include respond-below('xs') {
        width: 100% !important;
        top:0;
        left: 0;
     }
}

.row-per-page-wrapper {
    display:inline;
     position:absolute; 
     top:4px;
     left: 80px;
     border:1px solid red;
}

.max-xs-hidden {
    @include respond-below('xs') {
    display: none;
    }
}

.max-xs-margin {
    @include respond-below('xs') {
        margin-left: 1em !important
        }
}

.max-xs-align {
    @include respond-below('xs') {
        display: flex;
        justify-content: start !important;
        align-items: center;
    }
}

.max-xs-width-control  {
    @include respond-below('xs') {
        display: block !important;
        width: 80% !important;
    }
}

.select-width-control-100 {
    @include respond-below('xs') {
        width: 100% !important;
    }
}

.max-xs-width-control-100  {
    @include respond-below('xs') {
        display: block !important;
        width: 100% !important;
    }
}

.max-xs-width-flex-100  {
    @include respond-below('xs') {
        display: flex !important;
        width: 100% !important;
    }
}

.max-xs-width-flex-577  {
    @media (max-width: 577px) {
        display: flex !important;
        width: 100% !important;
    }
}

.max-xs-width-control-577  {
    @media (max-width: 577px) {
        display: block !important;
        width: 100% !important;
    }
}

.max-xs-width-control-700  {
    @media (max-width: 700px) {
        display: block !important;
        width: 100% !important;
    }
}

.max-xs-width-control-1019  {
    @media (max-width: 1019px) {
        display: block !important;
        width: 100% !important;
    }
}

.width-modal {
    div{
        max-width: 700px !important;
    }
}

.max-xs-width-control-900  {
    @media (max-width: 900px) {
        display: block !important;
        width: 100% !important;
    }
    select, input {
        @media (max-width: 900px) {
            display: block !important;
            width: 100% !important;
        }
    }
}

.max-xs-width-control-1200  {
    @media (max-width: 1200px) {
        display: block !important;
        width: 100% !important;
    }
    select, input {
        @media (max-width: 1200px) {
            display: block !important;
            width: 100% !important;
        }
    }
}

.max-xs-width-control-1010 {
    @media (max-width: 1010px) {
        display: block !important;
        width: 100% !important;
    }
}

.media-width-control-529 {
    @media (max-width: 529px) {
        display: block !important;
        width: 80% !important;
    }
}

.media-width-control-845 {
    @media (max-width: 845px) {
        display: block !important;
        width: 80% !important;
    }
}

.mobile-view-allocation {
    display: none !important;
}

.mobile-view-allocation-dropdown {
    display: none !important;
}


@media (max-width: 1067px) {
    .not-mobile-view-allocation {
        display: none !important;
    }
    .mobile-view-allocation {
        display: block !important;
    }
    .mobile-view-allocation-dropdown {
        display: block !important;
        position: absolute;
        z-index: 200;
        top: 8px;
        right: 20px;
    }
}

.driver-respond-between-md-xs {
    @include respond-below('md') {
        display: block !important;
        width: 100% !important;
    }
}

.remove-p {
    @include respond-below('md') {
        padding: 0 !important;
        padding-left: 0em !important;
        padding-right: 0em !important;
    }
}

.flex--xs-column-reverse {
    @include respond-below('sm') {
        flex-direction: column-reverse !important;
    }
}

.max-block-lg {
    @include respond-below('lg') {
        display: block !important;
        width: 100% !important;
    }
}

.linkable-place {
    cursor: pointer;
    color: #000000;
}


.linkable-place-2 {
    cursor: pointer;
    color: #007CDE;
}
.num-box-min-wid {
    min-width: min-content;
}

.max-width-85 {
    max-width: 85px !important;
}

.z-index-200 {
    z-index: 200 !important;
}

.min-width-preview-popup {
    min-width: 920px !important;
}

.text-dark-cancel {
    button {
        span {
            color: #000000 !important;
        }
    }
}

#custom-tab-0 {
    border: 1px solid #007CDE !important;
    span {
        div {
            label {
                color : #007CDE !important;
            }
        }
    }
}

#custom-tab-1 {
    border: 1px solid #007CDE !important;
    span {
        div {
            label {
                color : #007CDE !important;
            }
        }
    }
}

#custom-tab-0.Mui-selected {
    background-color: #007CDE;
    border: 1px solid #007CDE !important;
    span {
        div {
            label {
                color : #ffffff !important;
            }
        }
    }
}

#custom-tab-1.Mui-selected {
    background-color: #007CDE;
    border: 1px solid #007CDE !important;
    span {
        div {
            label {
                color : #ffffff !important;
            }
        }
    }
}

.padding-form-select {
    padding: 10px 15px !important;
}

.box-shadow-search {
    box-shadow: 1px 5px 3px -4px rgba(125,125,125,0.75);
    -webkit-box-shadow: 1px 5px 3px -4px rgba(125,125,125,0.75);
    -moz-box-shadow: 1px 5px 3px -4px rgba(125,125,125,0.75);
}

.text-middle-pos {

        input {
            text-align: center !important;
            display: block !important;
        }
    
}

.tab_align {
    div {
        svg {
           display: none !important;
        }
    }
}

.analytic-overview-card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px !important;
    border-radius: 0.8em !important;
    height: 100px;
    background-color: #ffffff;
}

.preview-card-job {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
    border-radius: 0.8em !important;
    background-color: #ffffff;
}

.analytic-chart-card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px !important;
    border-radius: 0.8em !important;
    height: 100px;
    background-color: #ffffff;
}

.analytic-chart-card-jobsize {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px !important;
    border-radius: 0.8em !important;
    height: 100px;
    background-color: #ffffff;
    padding: 20px 0px !important;
}

.analytic-chart-card-wh {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px !important;
    border-radius: 0.8em !important;
    background-color: #ffffff;
}

.custom-card-none-margin-x {
    margin: 20px 0px !important;
}

.custom-card-margin-x {
    margin: 20px 25px !important;
}

.h-550px {
    height: 550px !important;
}

.h-500px {
    height: 500px !important;
}

.h-450px {
    height: 450px !important;
}

.h-400px {
    height: 400px !important;
}

.h-420px {
    height: 420px !important;
}

.h-180px {
    height: 180px !important;
}

.h-190px {
    height: 190px !important;
}

.h-380px {
    height: 380px !important;
}

.h-350px {
    height: 350px !important;
}

.h-300px {
    height: 300px !important;
}

.h-250px {
    height: 250px !important;
}

.h-200px {
    height: 200px !important;
}

.h-150px {
    min-height: 150px !important;
}

.h-800px {
    height: 800px !important;
}

.legend-padding-bar {
    .recharts-legend-wrapper {
        ul {
            li {
                margin-right: 40px !important;
            }
        }
    }
}

.legend-padding-bar-packages {
    .recharts-legend-wrapper {
        height: 100px !important;
        overflow: auto;
        ul {
            display: flex;
            flex-direction: row-reverse;
            justify-content: start;
            li {
                display: inline-flex !important;
                align-items: center;
                padding-right: 20px !important;
            }
        }
    }
}

.legend-padding-pie {
    div {
        ul {
            display: flex !important;
            justify-content: center !important;
            width: 100% !important;
        }
    }
}

.spinner-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    color: #007cde;
}

.count-chart {
    font-size: 24px;
    font-weight: bolder;
}

.desc-chart {
    font-size: 16px;
    color: #777;
}

.legend-padding-pie {
    div {
        ul {
            flex-wrap : wrap !important;
            li {
                margin: 10px !important;
            }
        }
    }
}

div {
    button[title="Draw a circle"] { 
        display: none !important; 
    }
}

div {
    button[title="Draw a rectangle"] { 
        display: none !important; 
    }
}

div {
    button[title="Draw a line"] { 
        display: none !important; 
    }
}

.text-end-style {
    text-align: end;
    align-items: center;
    margin: auto;
}

.display-none {
    display: none !important;
}

.padding-right-select {
    select {
        padding-right: 0px !important;
    }
}

.hidden-field {
    display: none !important;
}

.download-order-template {
    border: 0px solid #000;
    background-color: transparent;
    color: #007CDE;
}

.do_warning {
    background-color: #ff996655;
    padding: 6px 10px;
    margin: 5px 0px;
    border-radius: 7px;
    font-size: 11px;
}

.z-index-allocation {
    div {
        div{
            div {
                div {
                    border-radius: 15px;
                    margin-bottom: 5px;
                }
                }
            }
        }
        z-index: 1500;
    }
   
.bg-red-alert {
    border-left: 5px solid red;
} 

.reason_for_failure {
    div div div div div table thead tr th {
        background-color: #ffffff !important;
        border: 0px solid transparent !important;
    }
}

.hidden-width-880 {
    div {
        @media (max-width: 880px){
            display: none !important;
        }
    }
}

.bg-gray {
    div:nth-child(2){
        background-color: #bebebe;
    }
}

.MuiInput-underline:before {
    border-bottom: 0px solid transparent !important;
}

.MuiInput-underline:after {
    border-bottom: 0px solid transparent !important;
}

.input-new-order-form {
    border: 1px solid #E0E1E4 !important;

}


#scrollbar-dual::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
    border-radius: 50px;
}

#scrollbar-dual::-webkit-scrollbar
{
	width: 4px;
	background-color: #cecece;
    height: 10px;
    border-radius: 50px;
}

#scrollbar-dual::-webkit-scrollbar-thumb
{
	background-color: #007CDE;
    height: 10px;
    border-radius: 50px;
}

.pagination {
    display: flex;
    align-items: center;
  
    margin-top: 40px;
    padding: 0;
  
    font-size: 18px;
    font-weight: 700;
    list-style: none;
    user-select: none;
  
    .page-item {
      min-width: 25px;
      min-height: 25px;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      margin: 0 2px;
  
      transition: all 0.3s;
  
      &:hover {
        color: $accent;
        background: $light-gray;
      }
  
      &.active {
        padding: 0;
  
        color: $light;
        background: $base;
      }
  
      &.arrow-icon {
        position: relative;
        z-index: 1;
  
        font-size: 35px;
  
        transform: translateY(-2px);
        transition: all 0.3s;
  
        &:after {
          content: "";
          width: 25px;
          height: 25px;
  
          position: absolute;
          z-index: -1;
          top: 56%;
          left: 50%;
  
          transform: translate(-50%, -50%);
        }
  
        &:hover {
          color: $accent;
          background: transparent;
  
          &:after {
            background: $light-gray;
          }
        }
  
        &.disabled {
          cursor: not-allowed;
  
          color: $gray;
  
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }

  .pagination-number {
    div:nth-child(2) {
        position: sticky;
        right: 0px;
    }
  }

  .pagination-class-container {
    div {
        table {
            tfoot {
                // display: none !important;
            }
        }
    }
  }

  .address-card {
    border-radius: 8px;
    border: 0.5px solid #bebebe;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    width: 100%;
  }

  .add-new-address {
    cursor: pointer;
    text-decoration: underline;
  }

  .address-overflow {
    height: 270px;
    overflow: auto;
  }

 .address-overflow::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
    border-radius: 50px;
}

.address-overflow::-webkit-scrollbar
{
	width: 4px;
	background-color: #cecece;
    height: 10px;
    border-radius: 50px;
}

.address-overflow::-webkit-scrollbar-thumb
{
	background-color: #007CDE;
    height: 10px;
    border-radius: 50px;
}

.overflow-auto-card {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.overflow-auto-card-xy {
    overflow-x: auto !important;
    overflow-y: auto !important;
}

.card-overflow {
    min-width: 730px !important;
    overflow-x: auto;
    overflow-y: hidden !important;
  }

 .card-overflow::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
    border-radius: 50px;
}

.card-overflow::-webkit-scrollbar
{
	width: 4px;
	background-color: #cecece;
    height: 10px;
    border-radius: 50px;
}

.card-overflow::-webkit-scrollbar-thumb
{
	background-color: #007CDE;
    height: 10px;
    border-radius: 50px;
}

/* Dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown header */
  .dropdown-header {
    background-color: #ffffff;
    color: #333;
    cursor: pointer;
  }
  
  /* Dropdown arrow icon */
  .dropdown-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
    margin-left: 5px;
    transform: translateY(2px);
  }
  
  /* Dropdown content */
  .dropdown-content {
    position: absolute;
    z-index: 123;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    display: none;
    min-width: 260px;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
    border-radius: 8px;
    padding: 10px;
    overflow-y:auto;
  }
  
  /* Show the dropdown content when the dropdown header is clicked */
  .dropdown:hover .dropdown-content {
    display: block;
  }

.pointer-cursor {
    cursor: pointer;
}

.active-address {
    background-color: #007CDE22;
}

.address-text-left {
    text-align: left;
    font-size: 10px;
}

.rbc-event-label {
    display: none !important;
}

.pb-35 {
    padding-bottom: 3.5rem!important;
}

.view-normal {
    background-color: #EBF4FF;
    padding: 10px;
    border-radius: 7px;
    width: 100%;
    margin: 10px 0px 0px 0px;
    min-height: 41.44px;
    overflow-wrap: anywhere;
}

.view-error {
    background-color: #FFEBEB;
    padding: 10px;
    border-radius: 7px;
    width: 100%;
    margin: 10px 0px 0px 0px;
    min-height: 41.44px;
    overflow-wrap: anywhere;
}

.view-border {
    border: 4px solid #EBF4FF;
    height: 100%;
    border-radius: 7px;
    margin: 10px 0px 0px 0px;
    min-height: 200px;
}

.view-border-error {
    border: 4px solid #FFEBEB;
    height: 100%;
    border-radius: 7px;
    margin: 10px 0px 0px 0px;
    min-height: 200px;
}

.icon-style-edit {
    height: 20px;
    color: #007edebe;
    margin: 0px 5px;
    &:hover {
        color: #007ede7e !important;
        height: 22px;
    }
}

.icon-style-trash {
    height: 20px;
    color: #ff0000be;
    &:hover {
        color: #ff00007e !important;
        height: 22px;
    }
}

.main-error-item {
    background-color: #fda9a9;
    border-radius: 7px;
    padding-bottom: 10px;
}

.entrance-animation  {
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

  .close-btn-circle {
    background-color: #007cde;
    width: 10px;
    height: 10px;
    padding: 10px;
    border-radius: 50%;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-right: 15px;
    &:hover {
        background-color: #0062ad !important;
        box-shadow: rgba(43, 43, 43, 0.24) 0px 3px 8px;
    }
  }

  .padding-under-900 {
    @media (max-width: 900px) {
        margin: 0px !important;
        padding-top: 28px !important;
    }
  }

  .margin-under-900 {
    @media (max-width: 900px) {
        margin: 0px !important;
    }
  }

  .bg-blue-card {
    background-color: #007CDE !important;
    color: #ffffff !important;
    div {
        color: #ffffff !important;
    }
  }

  .bg-secondary-card {
    background-color: #ddf7f380 !important;
  }
  .square-box {
    width: 25px; /* Adjust the width as needed */
    height: 25px; /* Adjust the height as needed */
    background-color: #007cde; /* Background color */
    border: 0px solid #000; /* Border style */
    display: inline-block;
    margin: 1px; /* Adjust the margin to add spacing between boxes */
    margin-left: 6px; /* Adjust the margin to add spacing between boxes */
  }
//   const colors = ["#FF2386", "#F9CD4D", "#F9CD4D", "#a9629e", "#FF8906", "#1ACE85", "#BB7BFD", "#F14254", "#696A79", "#000000"];
  .square-box-FF2386 {
    background-color: #FF2386 !important;
    &:hover {
        background-color: #FF238680 !important;
    }
    &:active {
        background-color: #FF238680 !important;
    }
    &:focus {
        background-color: #FF238680 !important;
    }
  }

  .square-box-F9CD4D {
    background-color: #F9CD4D !important;
    &:hover {
        background-color: #F9CD4D80 !important;
    }
  }

  .square-box-a9629e {
      background-color: #a9629e !important;
    &:hover {
        background-color: #a9629e80 !important;
    }
  }

  .square-box-F9CD4c {
      background-color: #F9CD4c !important;
    &:hover {
        background-color: #F9CD4c80 !important;
    }
  }

  .square-box-FF8906 {
      background-color: #FF8906 !important;
    &:hover {
        background-color: #FF890680 !important;
    }
  }

  .square-box-1ACE85 {
      background-color: #1ACE85 !important;
    &:hover {
        background-color: #1ACE8580 !important;
    }
  }

  .square-box-BB7BFD {
      background-color: #BB7BFD !important;
    &:hover {
        background-color: #BB7BFD80 !important;
    }
  }

  .square-box-696A79 {
      background-color: #696A79 !important;
    &:hover {
        background-color: #696A7980 !important;
    }
  }

  .square-box-F14254 {
      background-color: #F14254 !important;
    &:hover {
        background-color: #F1425480 !important;
    }
  }

  .square-box-000000 {
      background-color: #000000 !important;
    &:hover {
        background-color: #00000080 !important;
    }
  }

//   .lengend-position-flex-column{
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     justify-content: center;
//   }

  .size-pie-chart {
    .recharts-default-legend{
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    .recharts-legend-item {
        display: flex !important;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
  }

  .map-image-w {
    position: absolute;
    top: 23px;
    left: -59px;
    height: 150px;
  }

  .map-image-e {
    position: absolute;
    top: 34px;
    left: 106px;
    height: 84px;
  }

  .map-image-n {
    position: absolute;
    top: 2px;
    left: 17px;
    height: 96px;
  }

  .map-image-ne {
    position: absolute;
    left: 93px;
    top: 13px;
    height: 90px;
    transform: rotate(-5deg);
  }

  .map-image-ct {
    position: absolute;
    left: 37px;
    top: 51px;
    height: 99px;
    transform: rotate(8deg);
  }

  .map-image-container {
    padding-top: 30px;
    padding-left: 30px;
    position: relative;
    width: 450px;
    height: 300px;
    overflow: auto;
  }

  .scaled-svg {
    width: 200px;
    height: 200px;
    transform: scale(2);
  }

  .h-unset {
    height: 72px;
  }

  .form > div  {
    position: relative; 
    width: 30px;
    height:30px;
  }
  .form > div input {
    opacity: 0;
    width: 30px;
    height:30px;
  }
  .form > div label {
    position: relative
  }
  .form > div label::before {
    content: "\f00d";
    color: #fff;
    background-color: #02AF95;
    padding: 5px;
    border-radius: 3px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    top: -30px;
    left: -30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 30px;
    width: 30px;
  }
  .form > div:hover label::before {
    background-color: #ccc;
  }
  .form > div input[type="checkbox"]:checked + label::after {
    content: "\f00c";
    color: #fff;
    color: #fff;
    background-color: #02AF95;
    padding: 5px;
    border-radius: 3px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    top: -30px;
    left: -30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 30px;
    width: 30px;
  }
  .form > div input[type="checkbox"]:checked + label::before {
    content: "";
    color: #fff;
    background-color: #02AF95;
    padding: 5px;
    border-radius: 3px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    top: -30px;
    left: -30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 30px;
    width: 30px;
  }

  
  .hover-text {
    display: none;
  }

  .st0{
    fill:#FFC006;
    &:hover {
        fill:#FFC00680;
    + .hover-text {
        display: block;
        }
    }
}
  .st1{
    fill:#BCAAA3;
    &:hover {
        fill:#BCAAA380;
        content:'hello';
    }
}
  .st2{
    fill:#E91D62;
    &:hover {
        fill:#E91D6280;
        content:'hello';
    }
}
  .st3{
    fill:#E0E0F6;
    &:hover {
        fill:#E0E0F680;
        content:'hello';
    }
}
  .st4{
    fill:#FFC006;
    &:hover {
        fill:#FFC00680;
        content:'hello';
    }
}
  .st5{
    fill:#36C85D;
    &:hover {
        fill:#36C85D80;
        content:'hello';
    }
}
  .st6{
    fill:#E91D62;
    &:hover {
        fill:#E91D6280;
        content:'hello';
    }
}
  .st7{
    fill:#E91D62;
    &:hover {
        fill:#E91D6280;
        content:'hello';
    }
}
  .st8{
    fill:#E91D62;
    &:hover {
        fill:#E91D6280;
        content:'hello';
    }
}
  .st9{
    fill:#AD4ABF;
    &:hover {
        fill:#AD4ABF80;
        content:'hello';
    }
}
  .st10{
    fill:#00D3B4;
    &:hover {
        fill:#00D3B480;
        content:'hello';
    }
}
  .st11{
    fill:#F76260;
    &:hover {
        fill:#F7626080;
        content:'hello';
    }
}
  .st12{
    fill:#AD4ABF;
    &:hover {
        fill:#AD4ABF80;
        content:'hello';
    }
}

.map-label {
    position: absolute;
    top:80px;
    left: 50px;
}

// style={{position:'absolute', top: '-80px', left: '12px', width:'500px !important'}}
.map-posiiton-sg-container {
    position: absolute;
    left: 0px;
}

.map-posiiton-sg {
    position: absolute;
    top: -80px;
    width:500px !important
}

.overflow-auto-card-xy {
    // width: 820px !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.overflow-ttt {
    div {
        height: 115px !important;
        overflow: auto;
    }
}